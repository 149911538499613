import { createTheme } from '@mui/material/styles';

const white = '#FFFFFF';
const black = '#000000';

export const overridings = {
  name: 'SACD Default Theme',
  palette: {
    primary: {
      main: '#dc291b',
      dark: '#b6180c',
    },
    secondary: {
      main: '#0c2043',
      light: '#1c4378',
    },
    error: {
      main: '#fa4141',
      primary: '#ffe8e8',
      secondary: 'rgba(250,65,65,0.12)',
      tertiary: '#ff2946',
    },
    info: {
      main: '#dcecff',
      light: '#dcecff',
      dark: '#dcecff',
    },
    color: {
      grey: '#9d9d9d',
      darkGrey: '#656a72',
      darkGrey2: '#3d3d3d',
      darkIndigo: 'rgba(12, 32, 67, 0.3)',
      lightGrey: '#bcbcbc',
      veryLightGrey: '#f6f7f8',
      veryLightGrey2: '#f2f2f2',
      paleGrey: '#f5f6fa',
      green: '#7ec438',
      corail: '#ff7f51',
      blueLight: '#dcebff',
      blueDark: '#07152c',
      blueMarine: '#153161',
      white,
      black,
      paleDark: '#232323',
      lightBlack: 'rgba(255, 255, 255, 0.3)',
      pink: '#fff4f4',
    },
    background: {
      default: '#fff',

    },
  },
  shadows: {
    default: '0 0.125rem 0.4375rem 0 rgba(0,0,0,0.08)',
  },
};

export default createTheme(overridings);
