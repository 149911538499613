import { css } from 'styled-components';

export const flexCenter = css`
  display: flex;
  justify-content: center;
`;

export const flexCenterAll = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const flexBetween = css`
  display: flex;
  justify-content: space-between;
`;

export const flexStart = css`
  display: flex;
  justify-content: flex-start;
`;
export const flexStartAll = css`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const flexEnd = css`
  display: flex;
  justify-content: flex-end;
`;

export const flexEndAll = css`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const flexSpaceEvenly = css`
  display: flex;
  justify-content: space-evenly;
`;

export const flexSpaceAround = css`
  display: flex;
  justify-content: space-around;
`;

export const shadowAndBorder = css`
box-shadow: ${({ theme }) => theme.shadows.default};
border: solid 0.0625rem rgba(142,153,168,0.15);
`;
