import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import COMMON_EN from './resources/en/common.json';
import COMMON_FR from './resources/fr/common.json';
import CONTACTFORM_EN from './resources/en/contactForm.json';
import CONTACTFORM_FR from './resources/fr/contactForm.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next).init({
    lng: 'fr',
    // we init with resources
    resources: {
      fr: {
        common: COMMON_FR,
        contactForm: CONTACTFORM_FR,
      },
      en: {
        common: COMMON_EN,
        contactForm: CONTACTFORM_EN,
      },
    },
    fallbackLng: 'fr',
    debug: false,

    // have a common namespace used around the full app
    ns: ['common'],
    defaultNS: 'common',

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
    },

    react: {
      wait: true,
    },
  });

export default i18n;
