import styled from 'styled-components';

import { Paper, Typography, Autocomplete } from '@mui/material';
import Modal from '@xnet/react_base/dist/components/Modal';
import Button from '@xnet/react_base/dist/components/Button';
import Input from '@xnet/react_base/dist/components/Input';
import {
  flexBetween, flexCenter, flexCenterAll, flexStart,
  flexStartAll,
} from 'shared/utils/mixins';

export const ButtonWrapper = styled.div`
  ${flexCenterAll};
  margin-top: 35px;
`;

/* istanbul ignore next */
export const MainPaper = styled(Paper)`
    padding: 20px 10px 30px 30px;
    background-color: ${({ theme }) => theme.palette.color.white};
    margin-bottom: 30px;
    box-shadow: 0 0 10px 0 ${({ theme, alert }) => (alert === 1 ? theme.palette.error.main : 'rgba(12, 17, 30, 0.15)')};
    width: 100%;
    ${({ theme }) => theme.breakpoints.down('md')} {
      p {
        font-size: 22px;
      }
  };
`;

/* istanbul ignore next */
export const StyledModal = styled(Modal)`
.MuiDialog-container {
  align-items: flex-start;
}
`;

/* istanbul ignore next */
export const ModalContainer = styled.div`
  ${({ theme }) => `
    padding-bottom: 6px;
    min-width: 400px;
    ${theme.breakpoints.down('sm')} {
      min-width: 10px;
      width: 100%;
    }
  `}
`;

/* istanbul ignore next */
export const WrapperField = styled.div`
${({ theme }) => `
  margin: 25px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  ${theme.breakpoints.down('md')} {
    flex-direction: column;
  }
  `}
`;

export const IbanWrapper = styled(WrapperField)`
  flex-direction: column;
`;

/* istanbul ignore next */
export const WrapperSpecialField = styled.div`
  margin-bottom: 35px;
`;

export const WrapperFieldFileInput = styled(WrapperField)`
margin: 20px 0px;
.MuiFormLabel-asterisk {
  margin: 5px;
  color: ${({ theme }) => theme.palette.primary.main};
}
`;

/* istanbul ignore next */
export const WrapperDatePicker = styled.div`
${({ theme }) => `
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;

  ${theme.breakpoints.down('md')} {
    flex-direction: column;
  }
  `}
`;

/* istanbul ignore next */
export const WrapperCheckBox = styled.div`
${({ theme }) => `
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  ${theme.breakpoints.down('md')} {
    flex-direction: column;
  }
  `}
`;

/* istanbul ignore next */
export const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 15px;
  font-weight: bold;
  text-transform: initial;
`;

/* istanbul ignore next */
export const TitleWrapper = styled.div`
  ${flexStart};
  align-items: center;
  gap: 12px;
  min-width: 55%;
`;

/* istanbul ignore next */
export const MainTitle = styled(Typography)`
    font-size: 1.3rem;
    color: ${({ theme }) => theme.palette.secondary.main};
    width: 90%;
`;

export const GreyButton = styled(Button)`
  box-shadow: none;
  border-radius: 20px;
  opacity: unset;
  width: 130px;
  p {
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 1em !important;
  }

  .MuiTypography-root {
    font-size: 14px;
  }

  &.MuiButton-contained {
    background-color: ${({ theme }) => theme.palette.color.veryLightGrey2};
    border: 1px solid ${({ theme }) => theme.palette.color.veryLightGrey2};
    color: ${({ theme }) => theme.palette.color.grey};

    ${({ hasAlert }) => hasAlert && `
      background-color: ${({ theme }) => theme.palette.primary.main};
      border: 1px solid ${({ theme }) => theme.palette.primary.main};
      color: ${({ theme }) => theme.palette.color.grey};
    `}

    &:hover {
      color: ${({ theme }) => theme.palette.color.white};
      background-color: ${({ theme }) => theme.palette.color.grey};
      border: 1px solid ${({ theme }) => theme.palette.color.grey};
    }

    &.Mui-disabled {
      color: ${({ theme }) => theme.palette.color.white};
      background-color: ${({ theme }) => (theme.palette.color.grey)};
      opacity: 0.4;
      pointer-events: unset;
      cursor: not-allowed;
    }
  }
`;

/* istanbul ignore next */
export const FragmentHeader = styled.div`
  ${flexBetween};
  align-items: center;
  width: 100%;
  div { height: unset; }
  margin-bottom: 15px;
`;

/* istanbul ignore next */
export const DataText = styled(Typography)`
    font-size: 16px;
    color: ${({ theme }) => theme.palette.secondary.main};
    margin-bottom: 5px;
    word-break: break-word;
`;

/* istanbul ignore next */
export const PasswordFormWrapper = styled.div`
    padding: 0px 32px;
    ${({ theme }) => theme.breakpoints.down('md')} {
      padding: 0px;
    };
`;

/* istanbul ignore next */
export const HintsWrapper = styled.div`
  ${flexCenter};
  flex-direction: row;
  align-items: flex-start;
  margin-top: 25px;
  ${({ theme }) => theme.breakpoints.down('md')} {
      flex-direction: column;
  };
`;

/* istanbul ignore next */
export const HintsGroup = styled.div`
  ${flexCenter};
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const WrapperTitle = styled.div`
  display: flex;
  margin-bottom: 25px;
`;

export const StyledText = styled.div`
  margin: 15px 0;
`;

export const StyledAutocomplete = styled(Autocomplete)`
  .MuiFormHelperText-root {
    color: ${({ theme }) => theme.palette.error.main};
    background-color: ${({ theme }) => theme.palette.error.primary};
    border-radius: 4px;
    padding: 8px;
    opacity: 1;
  }

  .MuiFormLabel-asterisk {
    margin-left: 5px;
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const StyledTextarea = styled(Input)`
.MuiInputBase-root {
  height: unset;
  padding: 5px;
}
`;

export const FragmentContent = styled.div`
  ${flexBetween};
  align-items: center;
  ${({ theme }) => theme.breakpoints.down('md')} {
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      gap: 15px;
  };
`;

export const FragmentInfo = styled.div`
  ${flexStartAll};
  flex-direction: column;
`;

export const FragmentActions = styled.div`
  ${flexStartAll};
  flex-direction: column;
  gap: 10px;
`;
