import React from 'react';
import { I18nextProvider, useTranslation } from 'react-i18next';
import styled, { ThemeProvider as StyledThemeProvider } from 'styled-components';
import informationIconBig from 'shared/themes/assets/img/informationIconBig.png';
import { flexCenterAll, flexStart } from 'shared/utils/mixins';
import { MainPaper } from 'screens/Account/components/styledComponents';
import Text from '@xnet/react_base/dist/components/Text';

import logo from 'shared/themes/assets/img/logo-sacd.png';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { StylesProvider } from '@mui/styles';

import defaultTheme from 'shared/themes/defaultTheme';
import i18n from 'shared/translation/i18n';
import { AppBar } from '@mui/material';
import { shape } from 'prop-types';

const MainWrapper = styled.div`
  max-width: 1200px;
  margin: auto;
  ${flexCenterAll};
  flex-direction: column;
  width: 100%;
`;

const StyledMainPaper = styled(MainPaper)`
  ${flexCenterAll};
  flex-direction: column;
  padding: 20px;
  margin-bottom: 20px;
`;

const StyledText = styled(Text)`
  margin-bottom: 20px;
  font-size: 18px;
`;

const StyledTextPrimary = styled(StyledText)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 18px;
  margin: 0;
  text-align: center;
  line-height: 30px;
  font-weight: bold;
`;

const WrapperImg = styled.div`
  ${flexCenterAll};
  margin: 30px 0;
`;

const StyledLink = styled.a`
  word-break: break-word;
  cursor: pointer;
  text-decoration: underline;
  color: ${({ theme }) => theme.palette.secondary.main};
`;

const DLLink = styled.a`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: bold;
`;

export const StyledAppBar = styled(AppBar)`
${({ theme }) => `
  height: 52px;
  ${flexStart};
  flex-direction: row;
  width: 100%;
  background-color: ${theme.palette.secondary.main};
  box-shadow: none;
  position: unset;
  margin-bottom: 15px;
`}
`;
export const StyledToolbar = styled.div`
  z-index: 1;
  width: 100%;
  top: 85px;

`;
export const WrapperLogo = styled.div`
${({ theme, iscreationusermode, ismaintenancemodeactive }) => `
  padding: 0 10px;
  width: 180px;
  background-color:  ${theme.palette.primary.main};
  &:hover {
    cursor: ${!iscreationusermode && !ismaintenancemodeactive ? 'pointer' : 'default'};
  }

  ${theme.breakpoints.down('md')} {
    padding: 0;
    width: auto;
  };
  `}
`;

export default function ObsoleteBrowser({ browserSupport = {} }) {
  const { t } = useTranslation();

  return (

    <StylesProvider injectFirst>
      <StyledThemeProvider theme={defaultTheme}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={defaultTheme}>

            <I18nextProvider i18n={i18n}>
              <CssBaseline />
              <MainWrapper>
                <StyledAppBar color="inherit">
                  <WrapperLogo>
                    <img src={logo} height={52} width="unset" alt="Logo SACD" />
                  </WrapperLogo>
                  <StyledToolbar />
                </StyledAppBar>

                <StyledMainPaper>
                  <StyledTextPrimary type="h2">{t('browserSupport.title')}</StyledTextPrimary>
                </StyledMainPaper>

                <WrapperImg>
                  <img src={informationIconBig} alt="warning-icon" title={t('browserSupport.title')} />
                </WrapperImg>

                <StyledMainPaper>
                  <StyledText type="h3">{t('browserSupport.description')}</StyledText>

                  <StyledText type="h3">
                    <DLLink href={browserSupport.downloadUrl} rel="noreferrer noopener" target="_blank">
                      {t('browserSupport.download')}
                    </DLLink>
                  </StyledText>

                  <StyledText type="h4">
                    {t('blockingAlerts.paragraph3')}
                    <StyledLink href="https://www.sacd.fr/fr/contactez-nous" target="_blank" rel="noopener noreferrer">
                      {t('blockingAlerts.paragraph4')}
                    </StyledLink>
                  </StyledText>

                </StyledMainPaper>
              </MainWrapper>
            </I18nextProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </StyledThemeProvider>
    </StylesProvider>
  );
}

ObsoleteBrowser.propTypes = {
  browserSupport: shape({}).isRequired,
};
