import './polyfills';
import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { useDeviceData } from 'react-device-detect';
import browsers from 'shared/consts/browserSupport';
import ObsoleteBrowser from 'screens/ObsoleteBrowser';
import Loader from '@xnet/react_base/dist/components/Loader';

const { browser } = useDeviceData();
const browserSupport = browsers[browser.name];
const isBrowserSupported = !browserSupport || parseInt(browser.major, 10) >= browserSupport.version;

const root = document.getElementById('root');

if (isBrowserSupported) {
  const App = lazy(() => import('./App'));
  ReactDOM.render(<Suspense fallback={<Loader />}><App /></Suspense>, root);
} else {
  ReactDOM.render(<ObsoleteBrowser browserSupport={browserSupport} />, root);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
